import React from 'react'
import tw, { styled } from 'twin.macro'

import Logo from 'components/logo'

import NavLink from './navlink'

const Header = styled.header(({ marginBottom }) => [
  tw`relative flex flex-row items-center justify-between bg-primary-200 py-2 px-4 border-b-8 border-primary-800`,
  marginBottom && tw`mb-4`,
])

const MobileHeader = ({ user, marginBottom = true }) => {
  return (
    <Header marginBottom={marginBottom}>
      <NavLink to="/">
        <Logo className="h-16" />
      </NavLink>

      <nav className="flex flex-row">
        <NavLink to="#who-is">ABOUT</NavLink>
        <a
          className="flex flex-row items-center mr-2 px-3 py-1 rounded-md border border-solid border-transparent font-bold text-primary-800"
          href="mailto:info@littlescholar.education"
        >
          CONTACT
        </a>
      </nav>
    </Header>
  )
}

export default MobileHeader
