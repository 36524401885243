import React from 'react'
import tw, { styled } from 'twin.macro'

import Logo from 'components/logo'

import NavLink from './navlink'

const Header = styled.div(({ marginBottom }) => [
  tw`bg-primary-200 py-2 px-4 border-b-12 border-primary-800`,
  marginBottom && tw`mb-4`,
])

const DesktopHeader = ({ authenticated, marginBottom = true, ...props }) => {
  return (
    <Header marginBottom={marginBottom}>
      <div className="container mx-auto flex flex-row items-center justify-between">
        <NavLink to="/">
          <Logo className="w-20 h-20" />
        </NavLink>

        <nav className="flex flex-row">
          <NavLink to="#who-is">ABOUT</NavLink>
          <a
            className="flex flex-row items-center mr-2 px-3 py-1 rounded-md border border-solid border-transparent font-bold text-primary-800"
            href="mailto:info@littlescholar.education"
          >
            CONTACT
          </a>
        </nav>
      </div>
    </Header>
  )
}

export default DesktopHeader
