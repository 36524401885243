import React from 'react'
import { Link } from 'gatsby'

import Logo from 'components/logo'
import ABLogo from 'static/images/logos/ab.png'
import AEILogo from 'static/images/logos/aei.png'
import ASGLogo from 'static/images/logos/asg.png'

const Footer = () => {
  return (
    <footer
      id="footer"
      className="mt-4 md:mt-10 px-4 md:px-0 flex flex-col items-center"
    >
      <Logo className="w-40 md:w-64" />
      <p className="font-serif text-2xl font-bold">LITTLE SCHOLARS</p>
      <div className="text-center">
        <p>10 N. Penn Avenue, Greenville, Pennsylvania, 16125 | USA</p>
        <p>P. +1 724.885.1136 | F. +11 724.638.5370</p>
      </div>

      <p className="font-light my-6 text-center">
        © Little Scholars is the intellectual property of American Baccalaureate
        2020
      </p>

      <div className="w-full bg-primary-800 py-2 text-white text-center">
        LITTLE SCHOLARS IS A DIVISION OF
      </div>

      <div className="flex flex-row h-32 md:h-48 py-6 space-x-8">
        <img className="h-full" src={ABLogo} atl="American Baccalaureate" />
        <img
          className="h-full"
          src={AEILogo}
          atl="American English Institute"
        />
        <img className="h-full" src={ASGLogo} atl="American Scholar Group" />
      </div>
    </footer>
  )
}

export default Footer
