import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

const NavLink = styled(Link)(() => [
  tw`flex flex-row items-center mr-2 px-3 py-1 rounded-md border border-solid border-transparent font-bold text-primary-800`,
  `
    &.active {
      // border: 1px solid white;
    }
    &:last-of-type {
      margin: 0px;
    }
  `,
])

export default NavLink
