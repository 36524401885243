import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import MobileHeader from './header-mobile'
import DesktopHeader from './header-desktop'

const Header = ({ marginBottom = true }) => {
  const authenticated = false
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px' })

  if (isSmallScreen) {
    return (
      <MobileHeader user={{ firstName: 'Hung' }} marginBottom={marginBottom} />
    )
  } else {
    return (
      <DesktopHeader
        authenticated={authenticated}
        marginBottom={marginBottom}
      />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
